import React, { useEffect, useState } from 'react';

import Skills from './sections/Skills';
import Experience from './sections/Experience';
import Projects from './sections/Projects';
import Education from './sections/Education';
import Interests from './sections/Interests';

export default function Home(props) {

  const [images, setImages] = useState([]);
  const [search, setSearch] = useState('');


  useEffect(() => {

  }, []);

  return (
    <div>
      <hr />
      <Skills />
      <hr/>
      <Experience />
      <hr/>
      <Projects />
      <hr/>
      <Education />
      <hr/>
      <Interests />
    </div>
  );
}