import logo from './logo.svg';
import './App.css';

import Header from './layout/Header';
import Home from './pages/home/Home';

function App() {
  return (
    <div className="App">
      <div className="App-container">
        <Header /> 
        <Home /> 
      </div>
    </div>
  );
}

export default App;
