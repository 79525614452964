import React, { useEffect, useState } from 'react';


export default function Projects(props) {


  return (
    <div className='home-section'>
      <h2>Projects</h2>
      <div className='home-item'>
        <div className='primary-item'>
          <div className='primary-item-name'><strong>Isms NFT</strong>, Interactive pop art NFT concept</div>
          <div className='primary-item-right'><a href='https://ismsnft.io/'>ismsnft.io</a></div>
        </div>
        <ul>
          <li>Composed algorithmically in Python combining colors, fonts, and historical & contemporary cultural maxims.</li>
          <li>Developed an interactive web interface to combine, customize, and animate NFTs for display or export.</li>
        </ul>
      </div>
      <div className='home-item'>
        <div className='primary-item'>
          <div className='primary-item-name'><strong>Financial Data Aggregation</strong> (multiple projects)</div>
          <div className='primary-item-right'></div>
        </div>
        <ul>
          <li>Managed API connections with Polygon.io, AlphaVantage, Kraken, TD Ameritrade, & more.</li>
          <li>Collected and recorded 5 years of 1m ohlcv data for top 8 cryptocurrencies & 6 index funds. </li>
        </ul>
      </div>
      <div className='home-item'>
        <div className='primary-item'>
          <div className='primary-item-name'><strong>Exonowear</strong>, Made-to-order online t-shirt store</div>
          <div className='primary-item-right'><a href='https://exonowear.com/'>exonowear.com</a></div>
        </div>
        <ul>
          <li>Integrated a print-on-demand supplier with Shopify and set up multiple online sales channels.</li>
          <li>Designed product graphics, marketing content, social accounts, and web components.</li>
        </ul>
      </div>
    </div>
  );
}