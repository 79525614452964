import React, { useEffect, useState } from 'react';

import CardList from '../../../layout/card-list/CardList';

export default function Skills(props) {

  const skillsList = [
    {
      text: 'React',
      link: undefined,
    },
    {
      text: 'Django',
      link: undefined,
    },
    {
      text: 'Flask',
      link: undefined,
    },
    {
      text: 'php',
      link: undefined,
    },
    {
      text: 'MySQL',
      link: undefined,
    },
    {
      text: 'Apache',
      link: undefined,
    },
    {
      text: 'Pandas',
      link: undefined,
    },
    {
      text: 'Jupyter Lab',
      link: undefined,
    },
    {
      text: 'Illustrator',
      link: undefined,
    },
    {
      text: 'Photoshop',
      link: undefined,
    },
  ];


  return (
    <div className='home-section'>
      <h2>Skills</h2>
      <CardList items={skillsList} />

    </div>
  );
}

/* <div className='list-row'>
  {
    skillsList.map((s, si) => {
      return si>=skillsList.length-1 ? (
        <p className='list-row-item'>{s.text}</p>
      ) : (
        <>
          <p className='list-row-item'>{s.text}</p>
          <div className='list-row-divider'></div>
        </>
      );
    })
  }
</div> */