import React, { useEffect, useState } from 'react';


export default function Experience(props) {



  return (
    <div className='home-section'>
      <h2>Experience</h2>
      <div className='home-item'>
        <div className='primary-item'>
          <div className='primary-item-name'><strong>Software Developer I</strong>, Nemours Children’s Health (Research)</div>
          <div className='primary-item-right'>Dec. 2019 - Present</div>
        </div>
        <ul>
          <li>Sole web developer for the Biomedical Research Informatics Center (BRIC), responsible for > 10 active websites & applications.</li>
          <li>Lead product lifecycle through ideation, development, launch & future roadmap for two proprietary applications.</li>
          <ul>
            <li>Research Services Portal (RSP) - Clinical research regulatory approval management tool.</li>
            <li>Clinical Trial Effort Tool (CTET) - Clinical trial budget approximation tool developed under purview of the VP of Research.</li>
          </ul>
          <li>Manage all communication and meetings for research coordinators and regulatory committee administrators to collect requirements and demo features. </li>
          <li>Build compact web interfaces & RedCAP API wrappers for custom research project data collection.</li>
          <li>Frequent work on ModelTalker, a speech synthesis app used to recreate patient voices, including a custom implementation of the core recorder contracted by an outside research institution.</li>
        </ul>
      </div>
      <div className='home-item'>
        <div className='primary-item'>
          <div className='primary-item-name'><strong>Operations & Tech Coordinator</strong>, Latchaw, Montgomery, and Peck Inc.</div>
          <div className='primary-item-right'>May 2018 - Nov 2019</div>
        </div>
        <ul>
          <li>Initiated & managed transition from DOS inventory management software to a modern cloud ERP system.</li>
          <li>Worked on sales analysis, inventory management, order entry, invoicing, & promotional content.</li>
        </ul>
      </div>
      <div className='home-item'>
        <div className='primary-item'>
          <div className='primary-item-name'><strong>Biomedical Department Intern</strong>, WellSpan Health</div>
          <div className='primary-item-right'>Summer 2017</div>
        </div>
        <ul>
          <li>Responsible for full annual survey & discretionary repair of all patient room equipment at 4 hospitals.</li>
        </ul>
      </div>
    </div>
  );
}