import React, { useEffect, useState } from 'react';

import CardList from '../../../layout/card-list/CardList';


export default function Interests(props) {

  const interestsList = [
    {
      text: 'Crypto',
      link: undefined,
    },
    {
      text: 'Economics',
      link: undefined,
    },
    {
      text: 'Design',
      link: undefined,
    },
    {
      text: 'Fashion',
      link: undefined,
    },
    {
      text: 'Tennis',
      link: undefined,
    },
    {
      text: 'Golf',
      link: undefined,
    },
    {
      text: 'Skiing',
      link: undefined,
    },
    {
      text: 'Fishing & Boating',
      link: undefined,
    },
  ];



  return (
    <div className='home-section'>
      <h2>Interests</h2>
      <CardList items={interestsList} />
    </div>
  );
}

// <div className='list-row'>
//         {
//           interestsList.map((i, ii) => {
//             return ii>=interestsList.length-1 ? (
//               <p className='list-row-item'>{i.text}</p>
//             ) : (
//               <>
//                 <p className='list-row-item'>{i.text}</p>
//                 <div className='list-row-divider'></div>
//               </>
//             );
//           })
//         }
//       </div>
