import React, { useEffect, useState } from 'react';


export default function Education(props) {



  return (
    <div className='home-section'>
      <h2>Education</h2>
      <div className='home-item'>
        <div className='primary-item'>
          <div className='primary-item-name'><strong>B.S. Biomedical Engineering, Engineering Mechanics Minor</strong></div>
          <div className='primary-item-right'>Sept 2015 - May 2019</div>
        </div>
        <p>Penn State University - University Park, PA</p>
        <ul>
          <li>Head developer & PM for thon.org website for THON 2019 - led a team of 3 devs.</li>
          <li>Winner of 2019 Data & Engineering Competition of Penn State StartUp Week.</li>
        </ul>
      </div>
    </div>
  );
}