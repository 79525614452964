import React, { useEffect, useState } from 'react';

import { Card } from './Card';

export default function CardList(props) {


  useEffect(() => {

  }, []);


  return (
    <div className='card-list'>
      {
        props.items.map((item, ii) => {
          return item.link ? (
            <a className='card-link' href={item.link ? item.link : ''} target='__blank__'>
              <div className='card' key={item.text} itemHeight={58}>{item.text}</div>
            </a>
          ) : (
            <div className='card' key={item.text} itemHeight={58}>{item.text}</div>
          )
        })
      }
    </div>
  );
}