import React, { useEffect, useState } from 'react';

import CardList from './card-list/CardList';

// import avatar from '../../media/avatar.jpg';
import avatar from '../../media/profile.png';
import github from '../../media/icons/github/github-white.png';


export default function Header(props) {

  const [search, setSearch] = useState('');

  const contactList = [
    {
      text: 'Delray Beach, FL',
      link: undefined,
    },
    {
      text: '717-654-7745',
      link: undefined,
    },
    {
      text: 'michaelwpeck2@gmail.com',
      link: undefined,
    },
  ];

  const gitList = [
    {
      label: 'Github (personal): ',
      // text: 'michaelpeck',
      text: (<div className='icon-card'><div>personal</div><img src={github}/><div>michaelpeck</div></div>),
      link: 'https://github.com/michaelpeck',
    },
    {
      label: 'Github (work): ',
      // text: 'michaelwpeck',
      text: (<div className='icon-card'><div>work</div><img src={github}/><div>michaelwpeck</div></div>),
      link: 'https://github.com/michaelwpeck',
    },
  ];


  useEffect(() => {

  }, []);

  return (
    <header className="App-header">
      <div className='avatar-crop'>
        <img className='avatar' src={avatar}/>
      </div>
      <h1>Michael Peck</h1>
      <CardList items={contactList}/>
      <CardList items={gitList}/>
    </header>
  );
}

{/* <div className='list-row'>
{
  contactList.map((c, ci) => {
    return ci>=contactList.length-1 ? (
      <p className='list-row-item' key={`contact-${ci}`}>{c.text}</p>
    ) : (
      <div key={`contact-${ci}`}>
        <p className='list-row-item'>{c.text}</p>
        <div className='list-row-divider'></div>
      </div>
    );
  })
}
</div>
<div className='list-row'>
{
  gitList.map((g, gi) => {
    return gi>=gitList.length-1 ? (
      <p key={`git-${gi}`} className='list-row-item'>{g.label + g.text}</p>
    ) : (
      <div key={`git-${gi}`}>
        <p className='list-row-item'>{g.label + g.text}</p>
        <div className='list-row-divider'></div>
      </div>
    );
  })
}
</div> */}