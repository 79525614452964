import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { css, keyframes } from "@emotion/react";

export const Card = styled('div', { shouldForwardProp: (prop) => prop !== 'properties' && prop !==  'length' && prop !==  'margin' })(
  ({ theme, properties, length, margin }) => ({
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    margin: 5,
    borderRadius: 10,
    background: 'rgb(238,174,202)',
    background: 'radial-gradient(circle, rgba(148,187,233,0.3181066176470589) 0%, rgba(255,255,255,0.0963979341736695) 80%, rgba(255,255,255,0.01) 100%)',
  }),
);

export const CardOld = styled('div', { shouldForwardProp: (prop) => prop !== 'properties' && prop !==  'length' && prop !==  'margin' })(
  ({ theme, properties, length, margin }) => ({
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    borderRadius: 10,
    background: 'rgb(238,174,202)',
    background: 'radial-gradient(circle, rgba(148,187,233,0.3181066176470589) 0%, rgba(255,255,255,0.0963979341736695) 80%, rgba(255,255,255,0.01) 100%)',
  }),
);

// export default function Card(props) {


//   useEffect(() => {

//   }, []);

//   return (
//     <div>
      
//     </div>
//   );
// }